import { Component, ViewChild, Inject } from '@angular/core';
import { SigninService } from '../signin/signin.service';
import { Observable } from 'rxjs/Observable';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as jwtDecode from 'jwt-decode';
import { AppSettings, VerificationSettings } from '../../app.config';
import { HeaderComponent } from '../header/header.component';
import { ButtonToasterService } from '../buttontoaster/buttontoaster.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyService } from '../company-service/company.service';

@Component({
  selector: "companyriderforgotpassword",
  templateUrl: "./companyriderForgotPassword.component.html",
  providers: [SigninService],
})
export class CompanyRiderForgotPassWordComponent {
  list: any = {};
  state: any = false;
  apiMessage: string;
  decoded: any = {};
  fronturl = AppSettings.FEURL;
  newList: any = {};
  PasLISt: any = {};
  showOtp = false;
  emailId: any;

  otp: any;
  showForgetPass = true;
  showPassreset = false;
  list_phon_code = AppSettings.phone_code;

  verifyMeth = VerificationSettings.verifyMethod;
  methodName = VerificationSettings.methodName;
    CompanyCode: string;

  constructor(
    private logService: SigninService,
    private router: Router,
    private companyservice: CompanyService,
    private Activateroute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ButtonToasterService
  ) {
    this.CompanyCode = this.Activateroute.snapshot.paramMap.get("code")
    console.log(this.CompanyCode,"===========>")
    this.companyservice.setcode(this.CompanyCode)
      this.newList.phcode = this.list_phon_code[1];
  }

  @ViewChild("dataForm") form: any;

  // hc = new HeaderComponent(null, null);

  clearMsg(): void {
    this.apiMessage = "";
  }

  // @Inject(HeaderComponent) hc: HeaderComponent;

  resetPass(input) {
    this.spinner.show();
    input.email = this.emailId;
    const resetObj = {
      newPwd: input.newpassword,
      conPwd: input.confirmpassword,
      email: this.emailId,
      otp: this.newList.otp,
    };
    this.logService
      .ResetPAss(resetObj)
      .then((res) => {
        if (res.success === true) {
          this.router.navigateByUrl(`/company-ridersignin/${this.CompanyCode}`);
          this.toastr.showtoast("success", res.message);
        } else {
          this.toastr.showtoast("error", res.message);
        }
        this.spinner.hide();
      })
      .catch((msg) => {
        this.toastr.showtoast("error", msg.message);
        this.spinner.hide();
      });
  }

  getVerify(input) {
    if (input.otp === this.otp) {
      // console.log(this.otp)
      this.showPassreset = true;
      this.showOtp = false;
    } else {
      this.toastr.showtoast("error", "Invalid OTP");
    }
  }

  getRecoverEmail(input) {
    this.spinner.show();
    this.emailId = input.email;
    this.logService
      .forgotPASS(input)
      .then((res) => {
        if (res.success === true) {
          this.showForgetPass = false;
          this.toastr.showtoast("success", res.message);
          this.showOtp = true;
          this.otp = res.OTP;
          this.showPassreset = false;
        } else {
          this.toastr.showtoast("error", res.message);
        }
        this.spinner.hide();
      })
      .catch((res) => {
        this.toastr.showtoast("error", res.message);
        this.spinner.hide();
      });
  }
}
