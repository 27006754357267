import { Component } from "@angular/core";
import { CommonService } from "../common/common.service";

@Component({
  selector: 'app-company-privacy-policy',
  templateUrl: './company-privacy-policy.component.html',
  providers: [CommonService],
  styleUrls: ['./company-privacy-policy.component.css']
})
export class CompanyPrivacyPolicyComponent {

  title: string;
  description: string;
  constructor(private cservice: CommonService) {
    this.title = "Privacy Policy";

    this.cservice.getAboutPAge(this.title)
      .then(res => {
        //console.log(res[0])
        this.title = res[0].title;
        this.description = res[0].desc;
      })
  }

}
