// ./angular-client/src/app/todo/todo.service.ts
// ./angular-client/src/app/todo/todo.service.ts
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AppSettings } from '../../app.config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';


@Injectable()
export class RiderWalletService {
  private apiUrl = AppSettings.WEB_ENDPOINT;
  private apiTUrl = AppSettings.API_ENDPOINT;
  private apiDRUrl = AppSettings.DRI_ENDPOINT;
  initial: boolean;

  constructor(private http: Http) {
  }

  RiderWalletDetails(): Promise<any> {
    let headers = new Headers();
    headers.append('x-access-token', localStorage.getItem('Tok'));
    let opts = new RequestOptions();
    opts.headers = headers;
    //   return this.http.post(this.apiUrl + 'admin/', inputs, opts)
    return this.http.get(this.apiDRUrl + 'myWalletHistory/', opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }
  private handleError(error: any): Promise<any> {
    const body = error.json();
    return Promise.reject(body || error);
  }
  private handleData(res: any) {
    let body = res.json();
    return body || {};
  }
  public getApiUrl() {
    return this.apiUrl + ' ';
  }

}





