import { Component } from '@angular/core';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { LanguageSettings } from "./app.config";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CompanyService } from './index/company-service/company.service';

@Component({
  selector: 'app-root',
  templateUrl: './index/main.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  hideHFooter: boolean = false;
  currentItem: string;
  urlsegment: any;
  private unsubscribe$ = new Subject<void>();
  urlLogo: any;
  driverLogo: any;
  // lastUrlSegment: string;
  constructor(
    private router: Router,
    private companyservice: CompanyService,
    private activateroute : ActivatedRoute,
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    translate.addLangs(LanguageSettings.languages);
    translate.setDefaultLang(LanguageSettings.defaultSelectedLang);
  }
  ngOnInit(){
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe$)
  )
      .subscribe(event => {
        console.log(event,"=")
        // const urlList = this.router.url;
        // const urlList = this.router.url.split('/');
        const urlList = this.router.url.split(" ");
       
         const lastUrlSegment = urlList[urlList.length - 1];
        const splitLength = lastUrlSegment.split("/")
        console.log(splitLength[splitLength.length - 1],splitLength)
        if(splitLength.length > 2){
          this.urlsegment = splitLength[splitLength.length - 2]
        }else{
          this.urlsegment = splitLength[splitLength.length - 1]
          console.log(this.urlsegment,"else")
        }
        if(this.urlsegment.startsWith('company')){
          this.companyservice.getCompanyData().then(
            res => {
              this.urlLogo = res.data.Urllogo;
              this.driverLogo = res.data.driverlogoUrl;
              console.log(res)
            }
          )
          this.hideHFooter = true
          if(this.urlsegment == "company-ridersignin" || this.urlsegment == "company-ridersignup"  || this.urlsegment == "company-driversignup" || this.urlsegment == "company-processing-trips" || this.urlsegment == "company-riderprofile" ){
            this.companyservice.setCompanyType(this.urlsegment)
            // localStorage.setItem("CompanyType",this.urlsegment)
            console.log(this.urlsegment,"set local storageeeeeeeeeeee")
          }
          else{
            this.companyservice.setCompanyType("rider")
            // localStorage.setItem("CompanyType","rider")
          }
          return this.urlsegment
        }else{
          this.hideHFooter = false
        }
      });
  }
  onActivate(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      const scrollToTop = window.setInterval(() => {
        const pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 200); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    }
  }
  sendChildMessage(){
        this.currentItem = this.urlsegment
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
