import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AppSettings } from '../../app.config';
@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private code: string | null = null;
  private companyTypeSubject = new BehaviorSubject<string | null>(null);
  private apiUrl = AppSettings.API_ENDPOINT;

  constructor(private http: Http){
    this.companyTypeSubject.next(localStorage.getItem('CompanyType'));
  }
  setcode(code: string) {
    this.code = code;
    localStorage.setItem("company-code",this.code)
    console.log(this.code,"Company-code==============>")
  }

  getcode(): string | null {
    return this.code;
  }

  getCompanyType() {
    return this.companyTypeSubject.asObservable();
  }

  setCompanyType(companyType: string) {
    console.log(companyType)
    localStorage.setItem('CompanyType', companyType);
    this.companyTypeSubject.next(companyType);
  }
  getCompanyData(): Promise<any> {
    const headers = new Headers();
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http.get(this.apiUrl + 'getcompanyData/' + localStorage.getItem('company-code'))
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError)
  }

  private handleData(res: any) {
    let body = res.json();
    console.log("body",body);
    
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    const body = error.json();
    return Promise.reject(body || error);
  }
  // getcode(): Promise<string | null> {
  //   return new Promise(resolve => {
  //     // You should replace this with your actual logic to fetch the trip ID
  //     setTimeout(() => {
  //       const tripId = this.code; // Assuming you have a way to obtain the trip ID
  //       resolve(tripId);
  //     }, 1000); // Simulating delay
  //   });
  // }
}