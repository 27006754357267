import { Component, Input, Output } from "@angular/core";
import { Route, Router } from "@angular/router";
import { EventEmitter } from "protractor";
import { Subscription } from "rxjs";
import { AppSettings } from "src/app/app.config";
import { CompanyService } from "../company-service/company.service";

@Component({
    selector: "Company-Header",
    templateUrl: "./company-header.html",
})

export class CompanyHeader {

  @Input() logoParams: any; 
    openoverly: any;
    menuclass: any;
    logoURL = AppSettings.LOGO_URL;
    logoWidth = AppSettings.LOGO_WIDTH;
    logoHeight = AppSettings.LOGO_HEIGHT;
  Menu: number;
  private localStorageSubscription: Subscription;
  urlLogo: any;
  driverlogo: any;

    constructor(private route: Router,
      private companyservice: CompanyService){
    }
    ngOnInit(): void {
        // window.location.href = this.fronturl+"/#/about";
      // if (localStorage.getItem('Tok') == null && localStorage.getItem('CompanyType') === 'company-ridersignin') {
      //   this.Menu = 1;
      // } else if (localStorage.getItem('Tok') == null && localStorage.getItem('CompanyType') === 'company-ridersignup') {
      //   this.Menu = 2;
      //   console.log("menu2 works")
      // } else if (localStorage.getItem('Tok') != null && localStorage.getItem('CompanyType') === 'rider') {
      //   this.Menu = 3;
      //   console.log("------->Menuu",this.Menu)
      // } 
      this.companyservice.getCompanyData()
      this.localStorageSubscription = this.companyservice.getCompanyType().subscribe(companyType => {
        console.log(companyType)
        if (companyType === 'company-ridersignin') {
          this.Menu = 1;
          this.companyservice.getCompanyData().then(
            res => {
              this.urlLogo = res.data.Urllogo
              console.log(res)
            }
          )
        }
        if (companyType === 'company-ridersignup') {
          this.Menu = 2;
          this.companyservice.getCompanyData().then(
            res => {
              this.urlLogo = res.data.Urllogo
              this.driverlogo = res.data.driverlogoUrl
              console.log(res.data.Urllogo)
            }
          )
          
        }
        if(companyType === 'company-driversignup')  {
          this.Menu = 4;
          this.companyservice.getCompanyData().then(
            res => {
              this.urlLogo = res.data.driverlogoUrl
              console.log(res.data.driverlogoUrl)
            }
          )
        }
        
        if (companyType === 'rider') {
          this.Menu = 3;
        }
        if(companyType === 'driver') {
          this.Menu = 3;

        }
      });
    }
    menuoption(event) {
        this.ngOnInit();
        this.openoverly = !this.openoverly;
        if (event === 1) {
          this.menuclass = 'sidbar-active';
          // this. ngOnInit();
        } else {
          this.menuclass = '';
          // this. ngOnInit();
        }
      }
      MYLogout(){
        if (confirm("Are you sure you want to log out?")) {
          localStorage.removeItem('Type')
          localStorage.removeItem('Tok')
          // this.route.navigate(['company-ridersignin'])
          this.navigateToRiderSignIn()
        } else {
        }
      }
      navigateToRiderSignIn(){
        console.log("signin")
        const codeValue = localStorage.getItem('company-code') // Replace this with your dynamic code value
        this.route.navigate(['/company-ridersignin', codeValue]);
      }
      navigateToRiderSignup(){
        console.log("signupppp")
        const codeValue = localStorage.getItem('company-code') // Replace this with your dynamic code value
        this.route.navigate(['/company-ridersignup', codeValue]);
      }
}
