/**
 * @license
 * Copyright Abservetech. All Rights Reserved.
 */
export class AppSettings {
  // public static API = "https://api.towner.taxi/"; 
  public static API = "https://api.towner.taxi/"; 
  public static API_ENDPOINT = AppSettings.API+"adminapi/"
  public static VEH_ENDPOINT = AppSettings.API + "api/";
  public static WEB_ENDPOINT = AppSettings.API + "webapi/";
  public static DRI_ENDPOINT = AppSettings.API + "api/";
  public static FILEURL = AppSettings.API + "public/";
  public static BLOBFILEURL = "https://stgenttowner.blob.core.windows.net/enttownercontainer/"
  public static BASEURL = AppSettings.API;
  public static FEURL = AppSettings.API + "landing/";
  public static APPNAME = "OIOT";
  public static COPYRIGHTURL_FOOTER = "OIOT";
  public static LOGO_WIDTH = "100";
  public static LOGO_HEIGHT = "100";
  public static LOGO_URL = "assets/images/logo.png";
  public static DEFAULT_LOCATION = "Ecuador";
  public static ZOOM_MAP_LOCATION = 10;
  public static toShowSeocndCurrency = false;
  public static secondCurrency = "USD";
  public static defaultcur = "₹";
  public static defaultTime = "Mins";
  public static defaultUnit = "KM";
  public static defaultPhoneCode = "+91";
  public static showServiceCity = false;
  public static showWhatWeOffers = true;
  public static defLat = 9.656456;
  public static defLng = 14.87878;

  public static phone_code = ["+593", "+91"];
  public static canToast = false;
}

export class featuresSettings {
  public static isDTS = false;
  public static isCityWise = true; //Vehicle and Fare etc act citywise  0/1
  public static isHotel = false; //Vehicle and Fare etc act citywise  0/1
  public static isServiceAvailable = true; //if not modify in getServiceBasicfare
  public static isCommisionToAdmin = true; //is at end of trip Commision need to take
  public static isMultipleCompaniesAvailable = true;
  public static isDoubleChargeNeeded = false; //is isDoubleChargeNeeded for crossing City limit
  public static applyNightCharge = true;
  public static applyPeakCharge = true;
  public static applyWaitingTime = true; //waiting time charge
  public static applyTax = true;
  public static applyCommission = true;
  public static applyPickupCharge = true;
  public static isRiderCancellationAmtApplicable = true;
  public static isDriverCancellationAmtApplicable = false;
  public static applyBlockOldCancellationAmt = true;
  public static isDriverCreditModuleEnabled = true; //Helps to show alert if credits are low, reduce commision from credits, etc
  public static adminCommision = "driverWallet"; //From Driver Wallet (driverWallet)
  public static payoutType = "driverPostpaid"; //Driver Have to Recharge to Take Trip. (driverPrepaidWallet,driverPostpaidWallet)
  public static deductAmountFromDriverWallet = "commision"; //this amount will be decuted from Wallet (totalFare,commision)
  public static isRiderReferalCodeAvailable = false;
  public static isPromoCodeAvailable = true;
  public static isOffersForRideAvailable = false;
  public static tripsAvailable = ["Daily", "rental", "outstation"]; //USED
  public static fareCalculationType = "normal"; //{indiaGst,normal}
  public static distanceUnit = "KM"; //KM or Miles
  public static showHailTrips = false; //show hail related stuffs
  public static payPackageTypes = ["topup", "subscription"]; // 'subscription,commission,topup'
  public static subPackageTypes = ["subscription"]; // 'subscription,commission,topup' - DRIVER SUBSCRIPTION MENU
  public static shareTaxi = false; // Is Vehicle Sharing Available in Vehicle Type
  public static taxFeeLabel = "Tax"; // Access Fee or Tax
  public static showDeliveryTrips = false; // Delivery Trips and Report
  // public static defaultVehicleInMap = 'Sedan'; // Gods View and Tracking
  public static defaultVehicleInMap = "ALL"; // Gods View and Tracking
  public static referenceCode = false;
  public static langAvailable = ["en", "es"];
  public static DefaultCountry = "101";
  public static DefaultState = "41";
  public static ServiceAvailableCity = localStorage.getItem("cityType");
}


export class VerificationSettings {
  public static isVerify = true;
  public static verifyMethod = "phone"; // ['phone','email']
  public static methodName = "Phone Number"; // ['Email Address','Phone Number']
}

export class LanguageSettings {
  public static languages = ["es", "en"];
  public static defaultSelectedLang = "en";
  public static setLanguageForMenus = true;
  public static fetchTranslateFilesFromAPI = true;
}
export class FarefieldConfig {
  public static showFareFromConfig = true; // MTD Fare
  public static showManuallyAddPickupCharge = true;
}

// abservetech
