// ./angular-client/src/app/todo/todo.service.ts
import { Injectable } from "@angular/core";
import { Http, Headers, RequestOptions, Response } from "@angular/http";
import { AppSettings } from "../../app.config";

import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";

@Injectable()
export class SectionService {
  private apiUrl = AppSettings.WEB_ENDPOINT;
  private apiTUrl = AppSettings.API_ENDPOINT;
  private veh_endpoint = AppSettings.VEH_ENDPOINT;

  initial: boolean;
  TapiUrl: string = AppSettings.VEH_ENDPOINT;
  token: string = localStorage.getItem("Tok");
  language: string = localStorage.getItem("accept-language");

  constructor(private http: Http) {
    // this.initial=false;
  }

  getDriverDetailsForATrip(inputs: any) {
    const headers = new Headers();
    headers.append("x-access-token", localStorage.getItem("Tok"));
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http
      .put(this.TapiUrl + "tripDriverDetails/", inputs, opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  riderFeedback(inputs: any) {
    const headers = new Headers();
    headers.append("x-access-token", localStorage.getItem("Tok"));
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http
      .put(this.TapiUrl + "riderFeedback/", inputs, opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  CancelTaxi(inputs: any) {
    console.log("inputs",inputs);
    let obj = {
      tripId: inputs
    }
    
    const headers = new Headers();
    headers.append("x-access-token", localStorage.getItem("Tok"));
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http
      .put(this.apiTUrl + "cancelCurrentTrip/", obj, opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  getVehicles(tok): Promise<any> {
    // let headers = new Headers();
    // headers.append('x-access-token', tok);
    // let opts = new RequestOptions();
    // opts.headers = headers;
    return this.http
      .post(this.TapiUrl + "serviceBasicFare/daily", tok)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  requestTaxi(inputs: any): Promise<any> {
    const headers = new Headers();
    headers.append("x-access-token", localStorage.getItem("Tok"));
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http
      .post(this.TapiUrl + "requestTaxi/", inputs, opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }
  getRequesttaxi(tripId){
    const headers = new Headers();
    headers.append("x-access-token", localStorage.getItem("Tok"));
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http
      .get(this.veh_endpoint + "recentMTDRequestforlanding?tripId=" + tripId, opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }
  getTripdata(triptype){
    const headers = new Headers();
    headers.append("x-access-token", localStorage.getItem("Tok"));
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http
      .get(this.veh_endpoint + "Tripcheck?triptype=" + triptype, opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }
  listVehicles(): Promise<any> {
    const headers = new Headers();
    headers.append("x-access-token", localStorage.getItem("Tok"));
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http
      .get(this.apiTUrl + "whatweofferfrontend?language=" + localStorage.getItem("accept-language") + '&landingtype=' + "OIOT")
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  conBook(inputs: any): Promise<any> {
    if (localStorage.getItem("Tok")) {
      const headers = new Headers();
      headers.append("x-access-token", localStorage.getItem("Tok"));
      const opts = new RequestOptions();
      opts.headers = headers;
      return this.http
        .post(this.TapiUrl + "estimationFare/", inputs, opts)
        .toPromise()
        .then(this.handleData)
        .catch(this.handleError);
    } else {
      return this.http
        .post(this.apiTUrl + "estimationFare/", inputs)
        .toPromise()
        .then(this.handleData)
        .catch(this.handleError);
    }
  }

  getOurDrivers(): Promise<any> {
    const headers = new Headers();
    headers.append("x-access-token", localStorage.getItem("Tok"));
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http
      .get(this.apiTUrl + "ourDrivers?language=" + localStorage.getItem("accept-language"),opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  gethomecontent(): Promise<any> {
    const headers = new Headers();
    headers.append("x-access-token", localStorage.getItem("Tok"));
    const opts = new RequestOptions();
    opts.headers = headers;
    return this.http
      .get(this.apiTUrl + "gethomecontent?language=" + localStorage.getItem("accept-language") + '&landingtype=' + "OIOT",opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  private handleData(res: any) {
    const body = res.json();
    return body || {};
  }
  // createDoc(inputs:any): Promise<any>{
  //   let headers = new Headers();
  //   headers.append('x-access-token', myVariable);
  //   let opts = new RequestOptions();
  //   opts.headers = headers;

  //   return this.http.post(this.apiUrl + 'admin/', inputs, opts)
  //   .toPromise()
  //   .then(this.handleData)
  //   .catch(this.handleError)
  // }

  private handleError(error: any): Promise<any> {
    const body = error.json();
    return Promise.reject(body || error);
  }

  public getApiUrl() {
    return this.apiUrl + " ";
  }
}
